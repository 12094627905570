






























import Vue, { PropOptions } from 'vue';
import type { DropdownItem } from '@/components/Dropdown/DropdownItem.vue';

export type Dropdown = Blok & {
  label: string;
  items: DropdownItem[];
};

export default Vue.extend({
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Dropdown>,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
});
